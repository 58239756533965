
@font-face {
    font-family: "intro-black-regular";
    src: url("./Intro-Black-Regular.otf");
}

@font-face {
    font-family: "intro-bold";
    src: url("./Intro-Bold-Regular.otf");
}

@font-face {
    font-family: "intro-bold-caps";
    src: url("./Intro-Bold-Caps.otf");
}

@font-face {
    font-family: "intro-light-regular";
    src: url("./Intro-Light-Regular.otf");
}

@font-face {
    font-family: "intro-light-italic";
    src: url("./Intro-Light-Italic.otf");
}

@font-face {
    font-family: "intro-regular";
    src: url("./Intro-Regular.otf");
}

@font-face {
    font-family: "intro-book";
    src: url("./Intro-Book-Regular.otf");
}